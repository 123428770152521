export const features = [
    {
        "name": "Spotify Shuffler",
        "description": "Shuffled playlists without affecting your original playlist order",
        "uri": "/shuffle",
        "special": "",
        "icon": "shuffle.png"
    },
    {
        "name": "Analyse My Music",
        "description": "See what your most liked artists and albums are in your Liked Songs on Spotify",
        "uri": "/analysis",
        "special": " ",
        "icon": "video-player.png"
    },
    {
        "name": "Share Library",
        "description": "Create a playlist from your Liked Songs to easily share your music collection",
        "uri": "/share",
        "special": "",
        "icon": "headphones.png"
    },
    {
        "name": "Remove Shuffled",
        "description": "Easy function to clean up created shuffled playlists while keeping all your original playlists",
        "uri": "/delete",
        "special": "",
        "icon": "equalizer.png"
    }
];